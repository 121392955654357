import axios from 'axios'
import nprogress from 'nprogress'
import config from '@/config'
import vue from '@/main.js'

var instance = axios.create({
	timeout: 1000,
	baseURL: config.BASE_URL
});

//请求发送之前
instance.interceptors.request.use(config => {
	nprogress.start();
	config.headers.token = localStorage.getItem('token');
	return config
}, error => {
	nprogress.done();
	return Promise.reject(error)
});


//响应之后
instance.interceptors.response.use(({
	data: {
		data,
		code,
		msg,
		requestUrl
	}
}) => {
	nprogress.done();
	if (code == 201 && msg) throw vue.$fail(msg)
	if (code == 201 && requestUrl == '/api/v1/createOrder') return vue.$router.push({
		path: '/modifyAddress'
	})
	if (code == -1) vue.$router.push('/account')
	return data
}, error => {
	nprogress.done();
	vue.$fail('响应失败', error.message)
	return Promise.reject(error)
});



export default Vue => {
	Vue.mixin({
		methods: {
			$get(url, params) {
				return instance.get(url, {
					params
				})
			},
			$post(url, data) {
				return instance.post(url, data)
			},
			$put(url, data) {
				returninstance.put(url, data)
			},
			$del(url, data) {
				return instance.delete(url, data)
			}
		}
	})
}
