<template>
	<div class="search-wrap">
		<div class="title rse">快速精准查询物流运费信息</div>
		<div class="compute rss fww">
			<div class="start input-box rsc">
				<img src="~/assets/home/start.png" alt="" />
				<el-cascader placeholder="请选择起点" :props="{ expandTrigger: 'hover' }" class="cascader" size="medium" :options="regionData"
					@change="handleChange">
				</el-cascader>
			</div>
			<div class="end input-box rsc">
				<img src="~/assets/home/end.png" alt="" />
				<el-dropdown placement="bottom" @command="model.destination=$event">
					<span class="el-dropdown-link" style="cursor: pointer;">
						{{ model.destination || '请选择目的地'}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="item" v-for="(item, index) in options" :key="index">
							{{item}}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="input-box crm rsc">
				<div class="small rcc">
					<el-input placeholder="长度" v-model="length" clearable></el-input>
					<span>CM</span>
				</div>
				<span style="color: #999;">|</span>
				<div class="small rcc">
					<el-input placeholder="宽度" v-model="width" clearable></el-input>
					<span>CM</span>
				</div>
				<span style="color: #999;">|</span>
				<div class="small rcc">
					<el-input placeholder="高度" v-model="height" clearable></el-input>
					<span>CM</span>
				</div>
				<el-button type="primary" @click="handleComputed">自动计算体积重</el-button>
			</div>
			<div class="start input-box rsc ">
				<img src="~/assets/home/weight.png" alt="" />
				<div class="rbc a-input">
					<el-input placeholder="请输入重量" v-model="model.weight" clearable></el-input>
					<span>KG</span>
				</div>
			</div>
			<div class="input-box rsc ">
				<img src="~/assets/home/auto.png" alt="" />
				<div class="rbc a-input">
					<span>{{model.vwNum || '请计算体积重'}}</span>
					<span>CBM</span>
				</div>
			</div>
		</div>
		<!-- 查询价格 -->
		<div class="mian-btn">
			<el-button type="primary" @click="handleFreight">查询价格</el-button>
		</div>
		<div class="result-list" v-if="showResult">
			<a-table :rowKey="record=>record.price" :pagination="false" v-if="list.length" :columns="columns" :data-source="list" bordered>
				<template slot="price" slot-scope="text">
					<a>{{ text }}￥</a>
				</template>
			</a-table>
			<a-empty description="无数据" v-else />
		</div>
	</div>
</template>

<script>
	import {
		regionData,
		CodeToText,
	} from "element-china-area-data";
	export default {
		data() {
			return {
				columns: [{
					title: '物流名称',
					dataIndex: 'kuaidi',
				}, {
					title: '预估费用',
					dataIndex: 'price',
					scopedSlots: {
						customRender: 'price'
					},
				}],
				regionData,
				showResult: false,
				list: [],
				model: {
					startPoint: '',
					destination: '',
					vwNum: '',
					weight: '',
				},
				width: '',
				height: '',
				length: ''
			}
		},
		props: {
			options: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			handleChange(val) {
				val.forEach(v => this.model.startPoint += CodeToText[v])
			},
			async handleFreight() {
				let obj = {
					startPoint: '起点',
					destination: '目的地',
					vwNum: '体积',
					weight: '重量',
				}
				Object.keys(this.model).forEach(key => {
					if (!this.model[key]) throw this.$warn(`${obj[key]}不能为空!`);
				})
				if (parseInt(this.model.weight) <= 0 && parseInt(this.model.vwNum) <= 0) return this.$warn(
					'重量或者体积不能小于零!');
				let {
					list
				} = await this.$api.calculation(this.model)
				this.list = list
				this.showResult = true
			},
			handleComputed() {
				if (!this.length || !this.width || !this.height) return this.$warn('请输入体积长宽高!')
				this.model.vwNum = ((this.length * this.width * this.height) / 6000).toFixed(5)
			},
		}
	}
</script>

<style scoped lang="scss">
	@import "assets/style/index.scss";

	.search-wrap {
		margin-top: 100px;
		width: 1200px;
		padding: 35px 35px 30px;
		background: #fff;
		box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);

		.title {
			font-family: PingFang SC;
			font-weight: 500;
			height: 20px;
			font-size: 1.2rem;
		}

		.compute {
			width: 100%;
			padding-top: 20px;
			font-size: 1rem;
			color: #666666;

			.start {
				margin-right: 100px;
			}

			.input-box {
				width: 515px;
				height: 50px;
				background: #f7f6fb;
				border: 1px solid #eeeeee;
				border-radius: 4px;
				box-sizing: border-box;

				.el-input {
					width: 150px;
					margin-right: 30px;

					/deep/ .el-input__inner {
						width: 150px;
						height: 50px;
						font-size: 1rem;
						color: #000;
					}
				}


				.small {
					width: 310px;
				}
			}

			.crm {
				width: 100%;
				margin: 15px 0;

				.input-view {
					border: none;
				}
			}

			.end {

				.el-dropdown {
					width: 250px;

					font-size: 1rem;

					.el-icon-arrow-down {
						margin-left: 100px;
					}
				}
			}

			img {
				width: 23px;
				height: 23px;
				margin: 0 60px 0 30px;
			}

			.el-button {
				width: 282px;
				height: 50px;
			}

			.a-input {
				width: 270px;

				.el-input {
					width: 170px;
					margin-right: 30px;

					/deep/ .el-input__inner {
						width: 170px;
						height: 50px;
						font-size: 1rem;
						color: #000;
					}
				}
			}
		}

		.mian-btn {
			margin: 50px auto 0;
			width: 340px;
			height: 47px;
			border-radius: 4px;

			.el-button {
				width: 100%;
				height: 100%;
			}
		}

		.result-list {
			margin-top: 100px;
		}
	}
</style>
