import installFilters from './filter';
// import installDirectives from './directive';
import registerComponents from './components'
import installMixins from './mixin';
import extendVue from './vue-extend';
const plugin = {
	install(Vue, {
		silent
	} = {}) {
		//备份原本的log
		let oldLog = console.log
		// 重写console.log方法
		console.log = (...str) => {
		//...在函数参数中代表省略参数
			if (!silent) oldLog(...str)
		}
		extendVue(Vue)
		installMixins(Vue)
		installFilters(Vue)
		registerComponents(Vue)
	}
}
export default plugin
