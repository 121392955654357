import kebabCase from 'lodash/kebabCase'
// 自动化导入并注册全局组件
export default Vue => {
	//导入文件夹中的文件
	let context = require.context('.', true, /\.vue$/)
	context.keys().forEach(filePath => {
		let comName = filePath.replace(/\.\/(.+)\.vue$/, '$1')
		if (comName.includes('/')) {
			let arr = comName.split('/')
			conName = arr[arr.length - 1]
		}
		Vue.component(kebabCase(comName), context(filePath).default)
	})
	console.log('全局组件已部署')
}
