export default Vue => {
	Vue.mixin({
		methods: {
			$msg(message, type = "info") {
				this.$message[type](message)
			},
			$ok(message) {
				this.$msg(message, 'success');
			},
			$warn(message) {
				this.$msg(message, 'warning');
			},
			$fail(message) {
				this.$msg(message, 'error');
			}
		}
	})
}
