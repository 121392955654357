import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'nprogress/nprogress.css';
import 'normalize.css/normalize.css'
import ElementUI from 'element-ui'
import 'ant-design-vue/dist/antd.css';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.use(Antd);
import plugin from './plugin';
Vue.use(plugin, {
	silent: false //关闭部署打印
})

Vue.config.productionTip = false

var vue =new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
export default vue
