// 统一中转
import message from './message';
import request from './request';
import router from './router';
export default Vue => {
	message(Vue)
	request(Vue)
	router(Vue)
	console.log('自定义混入已部署')
}
