import vue from '@/main.js'

const user = {
	//注册
	userRegister: data => vue.$post('userRegister', data),
	//登录
	userLogin: data => vue.$post('userLogin', data),
	//个人中心
	userInfo: () => vue.$get('userInfo'),
}
export default user
