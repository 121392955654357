const filters = {
	dateFormat: (dataStr, pattern = 'yyyy-mm--dd hh:mm:ss') => {
		// 根据给定的事件字符串,得到特定的时间
		let dt = new Date(dataStr)

		// yyyy-mm-dd
		let y = dt.getFullYear()
		let m = (dt.getMonth() + 1).toString().padStart(2, '0')
		let d = dt.getDate().toString().padStart(2, '0')
		let w = dt.getDay();
		let week = "日一二三四五六" [w];
		let hh = dt.getHours().toString().padStart(2, '0')
		let mm = dt.getMinutes().toString().padStart(2, '0')
		let ss = dt.getSeconds().toString().padStart(2, '0')

		switch (pattern.toLowerCase()) {
			case 'yyyy-mm--dd':
				return `${y}-${m}-${d}`
			case 'hh:mm:ss':
				return `${hh}:${mm}:${ss}`
			case 'w':
				return `周${week}`
			default:
				return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
		}
	}
}
export default Vue => {
	Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
	// for (let key in filters) {
	// 	Vue.filter(key, filters[key])
	// }
	console.log('全局义过滤器已部署')
}
