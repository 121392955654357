import vue from '@/main.js'
const home = {
	//商城首页
	shopIndex: () => vue.$get('shopIndex'),
	//商品搜索
	productList: params => vue.$get('productList', params),
	//商品收藏/取消收藏
	productCollect: product_id => vue.$get('productCollect', {
		product_id
	}),
	//收藏列表
	userProductCollect: params => vue.$get('userProductCollect', params),
	//加入购物车
	addCart: params => vue.$get('addCart', params),
	//购物车列表
	cartList: () => vue.$get('cartList'),
	//修改购物车数量
	updateCart: params => vue.$get('updateCart', params),
	//删除购物车
	delCart: ids => vue.$get('delCart', {
		ids
	}),
	//立即购买
	nowBuy: params => vue.$get('nowBuy', params),
	//预览订单1
	prevOrder: cart_id => vue.$get('prevOrder', {
		cart_id
	}),
	//预览订单2
	confirmOrder: params => vue.$get('confirmOrder', params),
	//商品详情
	productInfo: id => vue.$get('productInfo', {
		id
	}),
	//创建订单
	createOrder: params => vue.$post('createOrder', params),
	//订单列表
	orderLst: params => vue.$get('orderList', params),
	//订单详情
	orderDetail: order_id => vue.$get('orderDetail', {
		order_id
	}),
	//重新支付
	orderRepay: order_id => vue.$get('orderRepay', {
		order_id,
		payType: 'native'
	}),
	//查询支付结果
	queryOrderPay: order_id => vue.$get('queryOrderPay', {
		order_id
	}),
	//去掉订单
	cancelOrder: order_id => vue.$get('cancelOrder', {
		order_id
	}),
	//删除订单
	delOrder: order_id => vue.$get('delOrder', {
		order_id
	}),
	//确认收货
	takeOrder: order_id => vue.$get('takeOrder', {
		order_id
	}),
	//收货地址
	addressLst: () => vue.$get('addressList'),
	//新增/编辑/删除地址
	changeAddress: params => vue.$post('changeAddress', params),
	//修改用户信息
	changeUserInfo: params => vue.$post('changeUserInfo', params),
	//文章详情
	articleInfo: id => vue.$get('articleInfo', {
		id
	}),
	//下单
	placeOrder: params => vue.$post('placeOrder', params),
	//客户列表
	customerList: () => vue.$get('customerList'),
	//客户信息
	customerInfo: id => vue.$get('customerInfo', {
		id
	}),
	//添加客户
	customerCreate: params => vue.$post('customerCreate', params),
	//修改客户
	customerUpdate: params => vue.$post('customerUpdate', params),
	//删除客户
	customerDel: id => vue.$get('customerDel', {
		id
	}),
	//搜索客户
	customerSearch: keyword => vue.$get('customerSearch', {
		keyword
	}),
	//行业动态
	customerSearch: cate => vue.$get('customerSearch', {
		cate
	}),
	//代购
	purchasing: params => vue.$post('purchasing', params),
	//分类
	cateList: () => vue.$get('cateList', {
		type: 1
	}),
	//行业动态
	articleList: () => vue.$get('articleList'),
	//公司简介、联系我们
	aboutUs: () => vue.$get('aboutUs'),
	//网站首页
	homeIndex: () => vue.$get('homeIndex'),
	//运费查询
	calculation: params => vue.$post('calculation', params),
	//底部信息
	footerInfo: () => vue.$get('footerInfo'),
	//充值
	toRecharge: money => vue.$post('toRecharge', {
		money,
		payType: 'native'
	}),
	//充值记录
	rechargeBill: params => vue.$get('userBill', {
		category: 'balance',
		...params
	}),
	//积分记录
	integralList: params => vue.$get('userBill', {
		category: 'integral',
		...params
	}),
	//新闻动态
	newsList: () => vue.$get('articleList', {
		cate: 6
	}),
}

export default home
