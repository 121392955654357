import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Index = () => import('views/index.vue')
const Home = () => import('views/Home/home.vue')
const Mall = () => import('views/Mall/mall.vue')
const RebateResult = () => import('views/Home/rebateResult.vue')
const Order = () => import('views/Order/order.vue')
const Overbooking = () => import('views/Order/overbooking.vue')
const OrderSystem = () => import('views/Order/orderSystem.vue')
const Profile = () => import('views/Profile/profile.vue')
const Customer = () => import('views/Profile/customer.vue')
const Operator = () => import('views/Common/operator.vue')
const AboutUs = () => import('views/Common/aboutUs.vue')
const MyBalance = () => import('views/Common/myBalance.vue')
const MyOrder = () => import('views/Common/myOrder.vue')



const routes = [{
		path: '/',
		component: Index,
		name: 'index',
		redirect: '/home',
		children: [{
				path: 'home',
				component: Home
			}, {
				path: 'account',
				component: () => import('views/account/index.vue')
			}, {
				path: 'rebate',
				component: RebateResult
			},{
				path: 'mall',
				component: Mall
			},
			{
				path: 'productDetail',
				component: () => import('views/Mall/detail')
			}, {
				path: '/step',
				redirect: '/ConfirmOrder',
				component: () => import('@/views/step/step'),
				children: [{
					path: '/ConfirmOrder',
					component: () => import('@/views/step/ConfirmOrder'),
					meta: {
						title: '确认订单',
					},
				}, {
					path: '/payOrder',
					component: () => import('@/views/step/payOrder'),
					meta: {
						title: '支付订单',
					},
				}, {
					path: '/result',
					component: () => import('@/views/step/result'),
					meta: {
						title: '支付结果',
					},
				}]
			}, {
				path: 'order',
				component: Order
			}, {
				path: 'orderSystem',
				component: OrderSystem,
				children: [{
					path: 'myAddress',
					name: 'myAddress',
					component: () => import('views/Common/myAddress'),
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'overbooking',
					name: 'overbooking',
					component: Overbooking,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'myOrder',
					name: 'myOrder',
					component: MyOrder,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'cart',
					name: 'cart',
					component: () => import('views/Common/cart.vue'),
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'myBalance',
					name: 'myBalance',
					component: MyBalance,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'customer',
					name: 'customer',
					component: () => import('views/Common/customer.vue'),
					meta: {
						keepAlive: true // 需要被缓存
					}
				}]
			}, {
				path: 'operator',
				component: Operator
			}, {
				path: 'article',
				component: () => import('views/Home/article.vue')
			}, {
				path: 'about',
				component: AboutUs
			}, {
				path: 'profile',
				component: Profile,
				children: [{
					path: 'myOrder',
					name: 'myOrder',
					component: MyOrder,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'myBalance',
					name: 'myBalance',
					component: MyBalance,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}, {
					path: 'customer',
					name: 'customer',
					component: Customer,
					meta: {
						keepAlive: true // 需要被缓存
					}
				}]
			}
		]
	},

]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

export default router
